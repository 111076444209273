
// const BASE_URL = "https://api.wappss.com/";
// const BASE_URL = "https://api.wappss.com/";
// const BASE_URL = "https://api.infinitemlmsoftware.com/";


// const BASE_URL = "http://127.0.0.1:5001/api/"; //local 
// const BASE_URL = "http://162.19.146.135:1478/api/";  //demo
const BASE_URL = "https://admin.boi.company/api/" //live
const DEFAULT_KEY = '149hfuysfgbgtfosgsdygso87dfgw988549sdgfs59' 
const PAYPAL_CLIENT_ID = "Ad077-Gx8st GcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID }
