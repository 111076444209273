import React ,{useRef} from "react";
import TableMain from "../Common/table/TableMain";
import { getLastPage } from "../../utils/getLastPage";
import { useTranslation } from "react-i18next";
import RepurchaseTableFilter from "../Common/table/RepurchaseTableFilter";
import { useReactToPrint } from "react-to-print";
import { exportToCSV, exportToExcel } from "../../utils/tableExports";
import { PrintReport } from "../Common/PrintReport";
import LabelledButton from "../Common/buttons/LabelledButton";


const RepurchaseTable = (props) => {
  const { t } = useTranslation();
  const headers = [
    "package",
    "amount",
    "date",
    // 'status'
  ];
  const reportHeaders = [
    // t("invoice_no"),
    t("package"),
    t("amount"),
    t("date"),
    // t('status')
  ];
  const lastPage = getLastPage(props.itemsPerPage, props?.data?.totalCount);


  const tableRef = useRef();
 
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });



  // Function to translate specific fields
  const addLangToValues = (obj) => {
    const newObj = {};
    for (const [key, value] of Object.entries(obj)) {
      if (key === "paymentMethod") {
        newObj[key] = t(value); // Translate the value using the i18n translation function
      } else {
        newObj[key] = value;
      }
    }
    return newObj;
  };

  const reportData = props.data?.data?.map((item) => {
    if (item.paymentMethod || item.status) {
      return addLangToValues(item);
    }
    return item; // Return unmodified item if paymentMethod and status are not present
  });

  return (
    <div className="ewallet_table_section">
      <div className="ewallet_table_section_cnt">
        <div className="filter_Section">
         
          <div className="row justify-content-end">
            <div className="col-md-4 text-end mob_filter_right">
              <LabelledButton
                className="fa fa-file-excel"
                text=" Excel"
                click={() => exportToExcel(reportData, reportHeaders, "purchase-data")}
              />
              <LabelledButton
                className="fa fa-file-text"
                text=" CSV"
                click={() => exportToCSV(reportData, reportHeaders, "purchase-data")}
              />
              <LabelledButton
                className="fa fa-print"
                text=" Print"
                click={handlePrint}
              />
            </div>
            <div style={{ display: "none", padding: "10px" }}>
              <PrintReport
                ref={tableRef}
                data={props.data?.data}
                headers={headers}
                type={"purchase-data"}
              />
            </div>
          </div>
        </div>
        {/* <RepurchaseTableFilter
          headers={headers}
          data={props.data?.data}
          type={"purchase-data"}
          reportHeaders={reportHeaders}
        /> */}
        <div className="table-responsive min-hieght-table">
          <TableMain
            headers={headers}
            data={props.data?.data}
            startPage={1}
            currentPage={props.currentPage}
            totalPages={lastPage}
            type={"purchase-data"}
            itemsPerPage={props.itemsPerPage}
            setItemsPerPage={props.setItemsPerPage}
            setCurrentPage={props.setCurrentPage}
            setEpinSortOptions={props.setSortOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default RepurchaseTable;
