import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import CurrencyConverter from "../../../Currency/CurrencyConverter";
import { formatDate } from "../../../utils/formateDate";
import { Modal } from "react-bootstrap";
import { ApiHook } from "../../../hooks/apiHook";
const InvoiceModal = ({ show, handleClose, data, currentRank }) => {
    const { t } = useTranslation();
    const tableRef = useRef();

    const conversionFactor = useSelector((state) => state?.user?.conversionFactor);
    const userSelectedCurrency = useSelector((state) => state?.user?.selectedCurrency);

    const handlePrint = useReactToPrint({
        content: () => tableRef.current,
    });

    console.log(data);
    
    const preview = ApiHook.CallLetterPreview(data?.username);
    const companyData = preview?.data?.companyData;
  
    return (
        <Modal show={show} onHide={handleClose} dialogClassName="custom-modal-dialog">
            <Modal.Header closeButton>
                <Modal.Title as={"h5"}>{t("reg_invoice")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="ewallet_table_section" ref={tableRef}>
                    <div className="ewallet_table_section_cnt">
                        <div
                            className="printBtnsec"
                            style={{ textAlign: "end", marginBottom: "5px" }}
                        >
                            <button
                                type="button"
                                className="btn print-button"
                                style={{ backgroundColor: "#954cea", color: "white" }}
                                onClick={handlePrint}
                            >
                                {t("print")}
                            </button>
                        </div>
                        <div className="table-responsive min-hieght-table">
                            
                            <div
                                className="cmpnyAddressname"
                                style={{ textAlign: "end", padding: "10px", display: "flex", justifyContent: "space-between" }}
                            >
                                <div>
                                    <img src={companyData?.logo ?? "/images/logo_user.png"} alt="" />
                                </div>
                                <div>
                                    <p style={{ marginBottom: "5px" }}>{data?.companyData?.name}</p>
                                    <p style={{ marginBottom: "0" }}>{data?.companyData?.address}</p>
                                </div>
                            </div>
                          
                            <table className="striped">
                                <tbody>
                                    <tr>
                                        <td>{t("username")}</td>
                                        <td>{data?.username}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("fullName")}</td>
                                        <td>{data?.fullName}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("sponsor")}</td>
                                        <td>{data?.sponsor}</td>
                                    </tr>
                                    <tr>
                                                <td>{t("dateOfJoining")}</td>
                                                <td>{formatDate(data?.dateOfJoining)}</td>
                                            </tr>
                                    
                                            <tr>
                                                <td>{t("package")}</td>
                                                <td>{data?.package?.name}</td>
                                            </tr>
                                            <tr>
                                                <td>{t("investAmount")}</td>
                                                <td>
                                                    {userSelectedCurrency?.symbolLeft} {CurrencyConverter(data?.joiningAmount, conversionFactor)}
                                                </td>
                                            </tr>
                                   
                                   
                                </tbody>
                            </table>
                        </div>
                      
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );

};

export default InvoiceModal;
